import { useEffect, useState } from 'react';
import "../css/prova.css"
function Prova() {
  
  /*--------------------------------------------------*/
  return (
    <div className="prova">      
      <div>
      <div class="container">
        <div>
          <button class="boto">Ostentiferous</button>
        </div>
      </div>
      </div>
    </div>
)
}

export default Prova;
