import { useEffect, useState } from 'react';
import "../css/politica.css"
function Politica() {
  
  /*--------------------------------------------------*/
  return (
    <div className='mainpolitica'>
        <div className="politica">
            <div className='titols'>Normativa de Protecció de Dades (RGPD)</div>
            <div className='textgran'>
                <h3>Introducció</h3>
                <p>Tot seguit us informem de la política de privacitat de Xavier Gil Pascual, d’acord amb la
                    normativa del Reglament (UE) 2016/679 del Parlament Europeu i del Consell, de 27 d’abril de
                    2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades
                    personals i a la lliure circulació d’aquestes dades i pel qual es deroga la Directiva 95/46/CE
                    (Reglament General de Protecció de Dades, en endavant «RGPD»).
                </p>
                
                <h3>Entitat responsable</h3>
                <p>La persona responsable de la protecció de dades és qui s’encarrega del compliment de la
                    normativa de protecció de dades; entre les seves funcions hi ha la de protegir les dades
                    personals que hàgiu proporcionat a Xavier Gil Pascual. Si teniu qualsevol dubte, consulta o
                    petició sobre el tema, li podeu escriure a adas@laplanxisteria.cat.
                    </p>
                <p>La planxisteria està constituïda segons la Llei 7/1997, de 18 de juny, d’associacions (DOG 2423,
                    d’1 de juliol) i la Llei orgànica 1/2002, de 22 de març, reguladora del dret d’associació (BOE
                    núm. 73, de 26 de març) i els seus estatuts.
                </p>
                <p>Els llocs web i adreces URL derivades de www.calibraciosistemesadas.cat (en endavant «llocs web») són
                    responsabilitat de Xavier Gil Pascual, i la informació que s’hi pugui recaptar s’incorporarà en
                    els seus sistemes de tractament.
                </p>

                <h3>Persona responsable</h3>
                <p>La persona responsable de la protecció de dades és qui s’encarrega del compliment de la
                    normativa de protecció de dades; entre les seves funcions hi ha la de protegir les dades
                    personals que hàgiu proporcionat a Xavier Gil Pascual. Si teniu qualsevol dubte, consulta o
                    petició sobre el tema, li podeu escriure a adas@laplanxisteria.cat.
                </p>
                <h3>Limitació de responsabilitat</h3>
                <p>Xavier Gil Pascual és responsable únicament de la informació que obté amb prèvia sol·licitud
                    dels usuaris dels seus llocs web, i no de cap altra informació personal que de manera eventual
                    aquests poguessin cedir, transmetre o facilitar mitjançant qualsevol altre mecanisme.
                </p>
                <h3>Transparència</h3>
                <p>Des de la Planxisteria intentem proporcionar als interessats una informació transparent i
                    concisa, en un llenguatge clar i senzill, pel que fa tant a les condicions del tractament de les
                    seves dades com a les respostes a l’exercici dels seus drets.
                </p>
                <h3>Tractament i conservació de les dades personals</h3>
                <p>L’objectiu de Xavier Gil Pascual es que el client quedi satisfet en tots els serveis que li posem a
                    l’abast, per tant, les dades personals que els usuaris proporcionin als llocs web es faran servir
                    exclusivament per fer-los arribar informació sobre els serveis que ofereix la Planxisteria.
                </p>
                <p>La base que empara els tractaments indicats és l’existència d’un interès legítim: es considera
                    que, com a interessats en els serveis de Xavier Gil Pascual, els usuaris donen el seu
                    consentiment pel que fa a l’esmentat tractament de les seves dades personals i a l’ús de
                    cookies en els llocs web. Això implica que:
                </p>
                <ul>
                    <li>
                        Les dades seran conservades mentre perduri el consentiment dels usuaris, excepte en
                        aquells casos en què Xavier Gil Pascual estigui obligada legalment a conservar-les.
                    </li>
                    <li>
                    D’acord amb els drets que els confereix la normativa vigent en protecció de dades, els
                    usuaris podran dirigir-se a l’autoritat de control competent per presentar la reclamació
                    que considerin oportuna, així com per exercir els drets d’Accés, Rectificació,
                    Cancel·lació i Oposició (ARCO) pel que fa al tractament de les seves dades de caràcter
                    personal.
                    </li>
                    <li>
                    Quan un usuari comuniqui a Xavier Gil Pascual que vol deixar d’aparèixer en els seus
                    fitxers, s’eliminaran totes les seves dades que no siguin estrictament necessàries per
                    completar les gestions administratives i acadèmiques dels cursos de Xavier Gil Pascual
                    a què s’hagi inscrit.
                    </li>
                </ul>
                <p>
                    Totes les dades es desen en els servidors de Xavier Gil Pascual o contractats per aquesta, en
                    territori espanyol.
                </p>
                <p>
                    El mer accés als llocs web, així com a qualsevol dels seus continguts i serveis, implica
                    l’acceptació sense reserves de la present política de privacitat, també aplicable prèviament a la
                    possible contractació dels serveis que Xavier Gil Pascual posi a disposició dels usuaris. Per tant,
                    és obligatori acceptar i complir aquesta política de privacitat abans d’accedir als llocs web o
                    contractar un servei de Xavier Gil Pascual.
                </p>
                <p>
                    L’usuari accepta que la navegació pels llocs web comporta la recollida i tractament de les seves
                    dades en els fitxers de Xavier Gil Pascual. Per tal que l’usuari sigui conscient de quina serà la
                    informació recopilada, com serà tractada i en quines circumstàncies es comunicarà, Xavier Gil
                    Pascual es compromet a actualitzar en aquesta pàgina els canvis que s’efectuïn en la política
                    de privacitat, així com a anunciar-los en qualsevol altre lloc que consideri apropiat.
                </p>
                <h3>Comunicació a tercers</h3>
                <p>
                    Xavier Gil Pascual no cedirà les dades personals de l’usuari a tercers, excepte per obligació
                    legal o requeriment judicial.
                </p>
                <h3>Informació sobre drets dels interessats i seguretat de la informació</h3>
                <p>
                    D’acord amb el que estableix per l’article 32 del Reglament (UE) 2016/679 del Parlament
                    Europeu i del Consell, de 27 d’abril de 2016, relatiu a la protecció de les persones físiques pel
                    que fa al tractament de dades personals i a la lliure circulació d’aquestes dades i pel qual es
                    deroga la Directiva 95/46/CE (Reglament general de protecció de dades), Xavier Gil Pascual es
                    compromet a adoptar les mesures tècniques i organitzatives necessàries per garantir la
                    integritat, confidencialitat i disponibilitat de les dades de l’usuari tenint en compte els riscos
                    que acompanyen el seu tractament.
                </p>
                <p>
                    A més a més, en cas que l’usuari introdueixi dades en els formularis dels llocs web de tercers,
                    seran aquests últims, i no Xavier Gil Pascual, els responsables de les dades de caràcter
                    personal i els que hauran d’adoptar les mesures tècniques descrites en el paràgraf anterior.
                </p>
                <h3>Drets ARCO</h3>
                <p>
                    D’acord amb el capítol 3 del RGPD, totes les persones poden exercir els drets d’Accés,
                    Rectificació, Cancel·lació i Oposició (ARCO) amb relació a les seves dades personals, de manera
                    que totes les entitats estan obligades a garantir-ne el compliment. Així, respecte a Xavier Gil
                    Pascual, a través del canal d’atenció xavigil@laplanxisteria.cat l’usuari pot exercir qualsevol dels
                    drets següents (cliqueu-hi a sobre per descarregar-vos el model de sol·licitud):
                </p>
                <ul>
                    <li>
                        Dret d’Accés: podeu consultar les vostres dades personals incloses en els nostres
                        fitxers.
                    </li>
                    <li>
                        Dret de Rectificació: podeu demanar-nos que modifiquem les vostres dades personals
                        si són inexactes.
                    </li>
                    <li>
                        Dret de Cancel·lació o supressió: podeu demanar-nos que eliminem les vostres dades
                        personals dels nostres fitxers.
                    </li>
                    <li>
                        Dret d’Oposició: podeu oposar-vos al tractament de les vostres dades personals per
                        part de Xavier Gil Pascual, sempre que hi hagi un motiu legítim i fonamentat per fer-
                        ho.
                    </li>
                </ul>
                <p>
                    L’usuari garanteix que les dades proporcionades són veritables, exactes, completes i
                    actualitzades i és responsable de qualsevol dany o perjudici, directe o indirecte, que pogués
                    ocasionar-se com a conseqüència de l’incompliment d’aquesta obligació tant a Xavier Gil
                    Pascual com a tercers.
                </p>
                <h3>Consentiment per al compliment de la LSSI-CE</h3>
                <p>
                    En compliment de la Llei 34/2002, d’11 de juliol, de serveis de la societat de la informació i de
                    comerç electrònic (LSSI-CE), Xavier Gil Pascual sol·licitarà a l’usuari el seu consentiment exprés
                    (i aquest, si així ho desitja, l’hi prestarà) per poder fer ús de les seves dades amb la finalitat
                    d’enviar-li informació sobre el lloc web i diferents ofertes i promocions relacionades.
                </p>
                <p>
                    Dins cada comunicació comercial de Xavier Gil Pascual es facilitarà a l’usuari un enllaç que li
                    permetrà revocar en qualsevol moment el seu consentiment a rebre-les.
                </p>
                <p>
                    Amb l’objectiu de garantir la seguretat i confidencialitat de les dades personals de l’usuari,
                    Xavier Gil Pascual ha adoptat els nivells de seguretat requerits per la normativa en protecció
                    de dades personals i ha instal·lat els mitjans tècnics al seu abast per evitar la pèrdua, mal ús,
                    alteració, accés no autoritzat i robatori de les dades personals facilitades.
                </p>
            </div>
        </div>

    </div>
)
}

export default Politica;
